import { render, staticRenderFns } from "./CreateUserPopup.vue?vue&type=template&id=96e799ee&scoped=true"
import script from "./CreateUserPopup.vue?vue&type=script&lang=js"
export * from "./CreateUserPopup.vue?vue&type=script&lang=js"
import style0 from "./CreateUserPopup.vue?vue&type=style&index=0&id=96e799ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96e799ee",
  null
  
)

export default component.exports