<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <md-card-content class="user-search-bar-container">
              <v-card class="user-search-bar-content">
                <v-row class="user-search-bar">
                  <v-col cols="9">
                    <v-text-field
                      v-model="usersPagination.search"
                      label="User Name / Email"
                      @input="debounceSearch"
                      clearable
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="ma-2 mt-3 user-create-button"
                      dark
                      outlined
                      :loading="userCreating"
                      :disabled="userCreating || !accountVerified && remainingUserCount <= 0"
                      @click="isUserCreatingFormOpen = true"
                    >
                      Create User
                    </v-btn>
                    <div v-if="accountVerified !== null && !accountVerified && remainingUserCount !== null">
                      <div>
                        Remaining user count: {{ remainingUserCount }}
                      </div>
                        <div v-if="remainingUserCount !== null && remainingUserCount <= 0" style="color:#ef5753;">
                          You have reached maximum allowed user count as an unverified user. Please verify your account to create more users.
                        </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </md-card-content>
          </form>
          <md-progress-spinner
            v-if="loading"
            class="color_stroke"
            md-mode="indeterminate"
          ></md-progress-spinner>
          <div v-else-if="usersPagination.list.length">
            <div v-for="user in usersPagination.list" :key="user.id">
              <user-item
                :user="user"
                :authorized-for-actions="authorizedForActions"
                @reinvite="reinviteUser"
                @remove="removeUser"
              />
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <pagination
            :current-page="usersPagination.page"
            :total-count="usersPagination.total"
            @load-page="getUsers"
            v-if="usersPagination.list.length && !loading"
          />
        </v-container>
      </v-col>
    </v-row>
    <create-user-popup
      ref="createUserPopup"
      :active="isUserCreatingFormOpen"
      :creating="userCreating"
      @close="isUserCreatingFormOpen = false"
      @create="createUser"
    />
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import restAdapter from "@/restAdapter";
import notification from "@/notification";
import CreateUserPopup from "./CreateUserPopup.vue";
import UserItem from "./UserItem.vue";
import Pagination from "@/components/Pagination.vue";
import debounce from "lodash/debounce";
import auth from "@/auth";
import { StatusCodes } from "http-status-codes";

export default {
  name: "Users",
  components: {
    CreateUserPopup,
    UserItem,
    Pagination,
  },
  data() {
    return {
      usersPagination: {
        search: "",
        page: 1,
        total: 0,
        list: [],
      },
      loading: false,
      userCreating: false,
      isUserCreatingFormOpen: false,
      accountVerified: null,
    };
  },
  mounted() {
    this.getUsers();
    this.accountVerified = auth.user.account.verified;
  },
  computed: {
    authorizedForActions() {
      return auth;
    },
    remainingUserCount() {
      const maxAllowed = 3;
      if(this.usersPagination.total === null) {
        return null;
      } 
      else{
      return Math.max(maxAllowed - this.usersPagination.total,0);
      }
    },
  },
  methods: {
    debounceSearch: debounce(function () {
      this.getUsers();
    }, 200),
    createUser(userDetails) {
      this.userCreating = true;
      restAdapter
        .post("/api/users", {
          first_name: userDetails.firstName,
          last_name: userDetails.lastName,
          email: userDetails.email,
        })
        .then(() => {
          this.$refs.createUserPopup?.close();
          this.getUsers(this.usersPagination.page);
          notification.success("User created successfully");
        })
        .catch((error) => {
          this.displayErrorsUsingResponse(error);
        })
        .finally(() => {
          this.userCreating = false;
        });
    },
    getUsers(page = 1) {
      this.loading = true;
      this.usersPagination.page = page;
      restAdapter
        .get(
          `/api/users?page=${this.usersPagination.page}&search=${this.usersPagination.search}`
        )
        .then((response) => {
          this.usersPagination.list = response.data.data;
          this.usersPagination.total = response.data.meta.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    reinviteUser(user) {
      const confirm = window.confirm(
        "Are you sure you want to resend the invitation email to this user?"
      );
      if (!confirm) {
        return;
      }
      restAdapter
        .post(`/api/users/${user.id}/reinvite`)
        .then(() => {
          this.usersPagination.list = this.usersPagination.list.map((u) => {
            if (u.id === user.id) {
              u.invitation_expired = false;
              u.invited_at = new Date();
            }
            return u;
          });
          notification.success("User reinvited successfully");
        })
        .catch((error) => {
          this.displayErrorsUsingResponse(error);
        });
    },
    removeUser(user) {
      const confirm = window.confirm(
        "Are you sure you want to delete this user?"
      );
      if (!confirm) {
        return;
      }
      restAdapter
        .delete("/api/users/" + user.id)
        .then((response) => {
          if (
            this.usersPagination.list.length === 1 &&
            this.usersPagination.page > 1
          ) {
            this.usersPagination.page = this.usersPagination.page - 1;
          }
          this.getUsers(this.usersPagination.page);
        })
        .catch((error) => {
          this.displayErrorsUsingResponse(error);
        });
    },
    displayErrorsUsingResponse(error) {
      if (
        error.response &&
        error.response.status === StatusCodes.BAD_REQUEST &&
        error.response.data &&
        error.response.data.error
      ) {
        notification.errors(error.response.data.error);
      } else {
        notification.error("Something went wrong, please try again later");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user-search-bar {
  background: #ffffff;
  border-radius: 7px;
}
.user-search-bar-content {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.user-search-bar-container {
  margin: 0px -15px;
}
.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
.user-create-button {
  border: 1px solid #473068;
  color: white !important;
  background-color: #473068;
}
</style>
