<template>
  <div class="md-layout-item">
    <md-card class="user-list-card">
      <div class="header">
        <div class="md-layout paddings">
          <div class="user-name font-weight-bold md-size-25 md-xsmall-size-100">
            {{ user.full_name }}
          </div>
          <div
            v-if="!user.email_verified_at"
            class="invitation-status font-weight-bold md-size-25 md-xsmall-size-100"
          >
            <invitation-status-label
              :is-email-verified="!!user.email_verified_at"
              :is-invitation-expired="!!user.invitation_expired"
              @resend="$emit('reinvite', user)"
            ></invitation-status-label>
          </div>
          <div class="user-name font-weight-bold md-size-25 md-xsmall-size-100">
            <div v-if="user.remove_able" @click="$emit('remove', user)">
              <v-icon class="delete-icon">mdi-delete</v-icon>
            </div>
          </div>
        </div>
      </div>
      <md-card-content class="card-content" style="border: 1px solid #6a7895">
        <div class="md-layout md-gutter" style="padding: 0px 10px">
          <div
            class="md-layout-item md-size-20 md-xsmall-size-100 seperator card-content-item"
          >
            <div class="user-details mt-1">
              <v-avatar size="70">
                <v-img
                  v-if="avatar"
                  :src="avatar"
                  @error="avatar = defaultAvatar"
                />
                <v-img v-else :src="defaultAvatar" />
              </v-avatar>
            </div>
          </div>
          <div
            class="md-layout-item md-size-40 md-xsmall-size-100 seperator card-content-item"
          >
            <div class="user-details mt-1">
              <div class="user-details mt-1">
                <span class="user-details-title">First Name: </span>
                <span class="user-details-value">{{ user.first_name }}</span>
              </div>
              <div class="user-details mt-1">
                <span class="user-details-title">Last Name: </span>
                <span class="user-details-value">{{ user.last_name }}</span>
              </div>
            </div>
          </div>
          <div
            class="md-layout-item md-size-40 md-xsmall-size-100 card-content-item"
          >
            <div class="user-details mt-1">
              <span class="user-details-title">Email: </span>
              <span class="user-details-value">{{ user.email }}</span>
            </div>
            <div v-if="user.email_verified_at" class="user-details mt-1">
              <span class="user-details-title">Joined At: </span>
              <span class="user-details-value">{{
                formatDateTimestamp(user.email_verified_at)
              }}</span>
            </div>
            <div
              v-else-if="user.invited_at"
              class="inquiry-material user-details mt-1"
            >
              <span class="user-details-title">Invited At: </span>
              <span class="user-details-value">
                {{ formatDateTimestamp(user.invited_at) }}
              </span>
            </div>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import moment from "moment";
import restAdapter from "@/restAdapter";
import InvitationStatusLabel from "./InvitationStatusLabel.vue";

export default {
  name: "UserItem",
  components: {
    InvitationStatusLabel,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  watch: {
    user: {
      handler() {
        this.retrieveUserAvatar();
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      avatar: null,
      defaultAvatar: require("../../assets/person.webp"),
    };
  },
  methods: {
    formatDateTimestamp(timestamp) {
      return moment(timestamp).format("HH:mm Do MMMM YYYY");
    },
    retrieveUserAvatar() {
      restAdapter
        .get(`/api/user/image_show/${this.user.id}`)
        .then((response) => {
          this.avatar = response.data.image;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.md-layout-item {
  margin-bottom: 20px;
}

.user-name {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
}

.header {
  background: #6a7895;
  height: calc(100% - 50%);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.paddings {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-content {
  padding-bottom: 16px !important;
}

.seperator {
  border-right: 1px solid #78909c;
}

.card-content-item {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.user-list-card {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.delete-icon {
  color: $danger;
  font-size: 24px;
  cursor: pointer;
}
</style>
