<template>
  <popup-window
    :active="active"
    :title="'Create User'"
    :hasBack="false"
    @popup-close="close"
  >
    <div>
      <v-form ref="createUserForm" class="mt-5" lazy-validation>
        <div>
          <v-text-field
            v-model="firstName"
            label="First Name"
            outlined
            clearable
            :rules="rules.firstNameRules"
            dense
            required
          />
        </div>
        <div>
          <v-text-field
            v-model="lastName"
            label="Last Name"
            outlined
            clearable
            :rules="rules.lastNameRules"
            dense
            required
          />
        </div>
        <div>
          <v-text-field
            v-model="email"
            label="Email"
            outlined
            clearable
            :rules="rules.emailRules"
            dense
            required
          />
        </div>
      </v-form>
      <div class="button-container">
        <ui-button class="primary-outlined long" @click="close" title="Close" />
        <ui-button
          class="primary long"
          :loading="creating"
          @click="createUser"
          title="Create"
        />
      </div>
    </div>
  </popup-window>
</template>

<script>
import validation from "@/validation";
import { load } from "@amcharts/amcharts4/.internal/core/utils/Net";
import { email } from "vuelidate/lib/validators";

export default {
  name: "CreateUserPopup",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    creating: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      rules: {
        firstNameRules: [
          (v) => !!v || "First Name is required",
          (v) =>
            (v && v.length <= 150) ||
            "First Name must be less than 150 characters",
          (v) =>
            (v && /^[a-zA-Z0-9_\s]+$/.test(v)) ||
            "First name should contain only letters, numbers and underscores",
        ],
        lastNameRules: [
          (v) => !!v || "Last Name is required",
          (v) =>
            (v && v.length <= 150) ||
            "Last Name must be less than 150 characters",
          (v) =>
            (v && /^[a-zA-Z0-9_\s]+$/.test(v)) ||
            "First name should contain only letters, numbers and underscores",
        ],
        emailRules: [
          (value) => {
            if (!value) {
              return "Email is required";
            }
            const validEmail = email(validation.emailFormatter(value));
            return validEmail || "Invalid email";
          },
          (v) =>
            (v && v.length <= 255) || "Email must be less than 255 characters",
        ],
      },
    };
  },
  methods: {
    close() {
      this.clearForm();
      this.$emit("close");
    },
    clearForm() {
      this.$refs.createUserForm.reset();
    },
    async createUser() {
      if (!this.$refs.createUserForm.validate()) {
        return;
      }
      this.$emit("create", {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
      });
    },
  },
};
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0px;
}
.md-button.primary-outlined.md-theme-default.primary {
  background-color: unset !important;
  border-color: unset !important;
}
</style>
